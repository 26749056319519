import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      pilihBalai: null,
      pilihProject: null,
      pilihSatker: null,
      isActive: [],
      listBalai: [],
      listProject: [],
      listSatker: [],
      listDataPaket: [],
      listDataPakets: [],
      tbData: [],
      dataEdit: {
        id: null,
        keterangan: null,
        pergeseran: null,
        percepatan: null,
        luncuran: null,
      },
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.G_GetRef("satker").then((data) => {
      this.listSatker = data;
    });
  },
  components: {
    myTable,
  },
  methods: {
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }
    },
    store() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);

      this.dataEdit.keterangan = formData.get("keterangan");

      this.G_PutAnyCustom(
        "sakti/semula-menjadi-gabungan/sm-item",
        this.dataEdit
      )
        .then((res) => {
          if (!res.status) {
            // this.getTable();
            this.$snotify.success("Berhasil Mengubah Data");
            this.$refs.modal.close();

            this.loadingTb = true;

            let myForm = document.getElementById("formCari");
            let formData = new FormData(myForm);
            var object = {};
            formData.forEach((value, key) => (object[key] = value));

            let filter = "";
            let myArray = [];

            if (object.satker) {
              // if (object.satker != "all") {
              // filter += "&kdsatker=" + object.satker;
              // }
              myArray = object.satker.split(",");
              myArray.forEach((v) => {
                filter += "&kdsatkers=" + v;
              });
            }

            if (this.$refs.form.validate()) {
              this.$refs.btnAct.load(true);
              this.G_GetAny(
                "sakti/semula-menjadi-gabungan?sEventId=2&mEventId=1" + filter
              ).then((res) => {
                if (res.status == 500) {
                  this.$refs.btnAct.load(false);
                  this.loadingTb = false;
                } else {
                  this.listDataPaket = res.data;
                  this.$refs.btnAct.load(false);
                  this.loadingTb = false;
                }
              });
            }
          } else {
            this.$snotify.warning(res.data.message, "Terjadi Kesalahan");
          }
        })
        .finally(() => {
          this.$refs.modal.loadingEnd();
        });
    },
    editData(id, keterangan, pergeseran, percepatan, luncuran) {
      this.$refs.modal.open("Edit Data", 600);

      this.dataEdit = {
        id: id,
        keterangan: keterangan,
        pergeseran: pergeseran,
        percepatan: percepatan,
        luncuran: luncuran,
      };
    },
    reGenerate() {
      let myForm = document.getElementById("formCari");
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      var listSatker = [];

      if (object.satker) {
        listSatker = object.satker.split(",");

        this.generate(listSatker, "Generate Manual Data");
      } else {
        this.$snotify.warning("Pilih Satker Telebih Dahulu");
      }
    },
    cariData() {
      $("#tableShow").hide();
      // this.$refs.giat.selectAll()
      let myForm = document.getElementById("formCari");
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      let filter = "";
      let myArray = [];

      if (object.satker) {
        myArray = object.satker.split(",");
        myArray.forEach((v) => {
          filter += "&kdsatkers=" + v;
        });
      }


      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);
        this.G_GetAny(
          "sakti/semula-menjadi-gabungan?sEventId=2&mEventId=1" + filter
        ).then((res) => {
          if (res.status == 500) {
            this.$refs.btnAct.load(false);
            this.loadingTb = false;
            $("#tableShow").hide();

            this.generate(myArray, res.data.message);
          } else {
            this.listDataPakets = res.data;

            $("#tableShow").fadeIn();
            this.$refs.btnAct.load(false);
            this.loadingTb = false;
          }
        });
      }
    },
    async generate(satker, title) {
      let judul = "Generate Data";
      let subJudul = title;

      if (await this.$refs.confirm.open(judul, subJudul)) {
        var param = {
          sEventId: 2,
          mEventId: 1,
        };
        if (satker.length > 0) {
          param["kdsatker"] = satker;
        }

        this.$refs.confirm.loading();
        // this.$refs.btnActGen.load(true)
        this.G_PostAnyCustom("sakti/semula-menjadi-gabungan/generate", param)
          .then((res) => {
            if (res) {
              this.$refs.confirm.close();
              this.$snotify.success(
                "Silahkan Cari Ulang Data Satker yang di pilih",
                res.message
              );
            } else {
              this.$snotify.warning(res.message);
            }
          })
          .finally(() => {
            // this.$refs.btnActGen.load(false)
            this.$refs.confirm.loadingEnd();
          });
      }
    },
  },
};
